import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
         A sample React App to embed in WordPress
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        
        <iframe title="Reacted to wordpress" width="560" height="315" 
            src="https://www.youtube.com/embed/z8CDk-bHFuU" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
        </iframe>
        <br/>
      </header>
    </div>
  );
}

export default App;
